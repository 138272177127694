<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    content: string;
    scaleToFit?: boolean;
    scale?: number;
  }>(),
  {
    scale: 1,
  }
);

const contentElement = ref<HTMLDivElement | null>(null);
const scaleValue = ref<number>(1);
const minScalingValue = 0.1;

const calcScaling = () => {
  if (!props.content) {
    scaleValue.value = 1;
    return;
  }

  scaleValue.value = props.scaleToFit
    ? calculateFitScaling()
    : Math.max(minScalingValue, Math.min(1, props.scale ?? 1));
};

const calculateFitScaling = () => {
  if (!contentElement.value) return 1;

  const fitScale = Math.min(
    contentElement.value.clientWidth / contentElement.value.scrollWidth,
    contentElement.value.clientHeight / contentElement.value.scrollHeight
  );

  return Math.max(minScalingValue, Math.min(1, fitScale));
};

watch([() => props.scale, () => props.scaleToFit], calcScaling);

const observer = new ResizeObserver(calcScaling);

onMounted(async () => {
  if (!contentElement.value) {
    return;
  }
  observer.observe(contentElement.value);
});

onUnmounted(() => {
  window.removeEventListener('resize', calcScaling);
  observer.disconnect();
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    ref="contentElement"
    class="content-element"
    :style="{ '--v-scaling-value': scaleValue }"
    v-html="content"
  />
</template>

<style lang="scss" scoped>
.content-element {
  max-width: 100%;
  max-height: 100%;
  --v-scaling-value: 1;
  transform-origin: 0 0;
  transform: scale(var(--v-scaling-value));
  transition: all 0.1s ease-in-out;
  :deep(img) {
    display: unset;
    max-width: unset;
  }
}
</style>
