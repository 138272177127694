<script setup lang="ts">
import { QDate, QTime } from 'quasar';
import { ref } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const model = defineModel<string>();

const updateModel = () => {
  switch (props.type) {
    case 'datetime':
      model.value = `${date.value} ${time.value}`;
      break;
    case 'date':
      model.value = date.value;
      break;
    case 'time':
      model.value = time.value;
  }
};

const props = withDefaults(
  defineProps<{
    id?: string;
    aid: string;
    type?: 'date' | 'time' | 'datetime';
  }>(),
  {
    type: 'datetime',
  }
);

const parts = model.value?.split(' ');
const date = ref(parts?.[0]);

let partIndex = 1;
if (props?.type === 'time') {
  partIndex = 0;
}
const time = ref(`${parts?.[partIndex]} ${parts?.[partIndex + 1]}`);
</script>

<template>
  <div class="ws-date-picker">
    <QDate
      v-if="type === 'datetime' || type === 'date'"
      v-model="date"
      :bordered="false"
      mask="MM/DD/YYYY"
      class="ws-date-picker-date"
      :aid="`${aid}_DATE_PICKER`"
      @update:model-value="updateModel"
    />
    <QTime
      v-if="type === 'datetime' || type === 'time'"
      v-model="time"
      :bordered="false"
      mask="hh:mm A"
      class="ws-date-picker-time"
      :aid="`${aid}_TIME_PICKER`"
      @update:model-value="updateModel"
    />
  </div>
</template>

<style scoped lang="scss">
.ws-date-picker {
  display: flex;
  gap: 10px;
  padding: 20px;

  &-date,
  &-time {
    color: var(color-gray-500);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    :deep(.q-time__main) {
      padding: 20px;
    }

    :deep(.q-date__header),
    :deep(.q-time__header) {
      height: 60px;
    }

    :deep(.q-date__header) {
      padding: 4px 22px;
    }

    :deep(.q-time__header) {
      min-height: 0;
    }

    :deep(.q-time__header-ampm) {
      font-size: 12px;
      font-weight: 600;
    }

    :deep(.q-date__calendar-item .q-btn) {
      font-size: 12px; // TODO: change size to be rem or var
    }

    :deep(.q-date__navigation .q-btn) {
      font-size: 12px; // TODO: change size to be rem or var
    }

    :deep(.q-time__container-child) {
      background: rgb(var(--color-gray-100));
    }

    :deep(.q-date__header-subtitle) {
      font-weight: 700;
      font-size: 12px;
      opacity: 1;
    }

    :deep(.q-date__header-title-label),
    :deep(.q-time__header-label) {
      font-weight: 700;
      font-size: 16px; // TODO: change size to be rem or var
    }

    :deep(.q-date__header),
    :deep(.q-time__header) {
      background-color: white;
      color: var(--color-gray-500);
      border-bottom: 1px solid rgb(var(--color-gray-100));
    }

    :deep(.q-date__months-item .q-btn),
    :deep(.q-date__years-item .q-btn) {
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
}
</style>
